import application from '../util/application'
import environment from '../util/environment'
import Timeout from '../util/timeout'

const config = application.configure('[flash-message]', {
  timeoutDelay: 5000,
  updateFrequency: 100,
})

up.compiler('[flash-message]', (element) => {
  const indicator = element.querySelector('[flash-message--timer-indicator]')
  const closeButton = element.querySelector('[flash-message--close]')

  function updateIndicator() {
    const percent = removeTimer ? removeTimer.percentRemaining : 100

    if (!environment.isTest) {
      up.element.setStyle(indicator, {
        transition: `width ${config.updateFrequency}ms linear`,
        width: `${percent}%`,
      })
    }
  }

  function removeAutomatically() {
    if (environment.isTest) {
      // We don't want to remove flashes automatically in feature tests because
      // - tests might not see flashes if they are slow,
      // - it could cause flickering behavior if flashes cover elements only sometimes.
    } else {
      updateIndicator()
      remove()
    }
  }

  function remove() {
    window.clearInterval(updateIndicatorInterval)
    removeTimer.clear()
    up.animate(element, 'move-to-top').then(() => { element.remove() })
  }

  const removeTimer = new Timeout(removeAutomatically, config.timeoutDelay)
  const updateIndicatorInterval = window.setInterval(updateIndicator, config.updateFrequency)

  updateIndicator()
  element.addEventListener('mouseenter', () => { removeTimer.pause() })
  element.addEventListener('mouseleave', () => { removeTimer.resume() })
  closeButton.addEventListener('click', remove)

  return remove
})
