import environment from '../../shared/util/environment'
import Deferred from '../modules/deferred'

const domReadDeferred = new Deferred()
const domReady = domReadDeferred.promise

document.addEventListener('DOMContentLoaded', () => { domReadDeferred.resolve() })

function sanitizeSecrets(object) {
  if (typeof object === 'object' && object instanceof Object) {
    // eslint-disable-next-line no-return-assign
    Object.keys(object).forEach((key) => object[key] = sanitizeSecrets(object[key]))
    return object
  } else {
    return object
  }
}
export function trackEvent(eventName, eventData = {}) {
  domReady.then(() => {
    gtmPush({
      event: eventName,
      eventData: sanitizeSecrets(eventData),
      path: sanitizeSecrets(location.pathname),
      env: environment.toString,
    })
  })
}

export function trackElementInteraction(element, eventName, customEventData = {}) {
  const eventData = {
    element: {
      id: element.getAttribute('up-id') || element.getAttribute('class'),
      text: element.textContent.trim().replace(/\s+/g, ' '),
    },
  }
  Object.assign(eventData, customEventData)
  trackEvent(eventName, eventData)
}

function gtmPush(eventData) {
  if (eventData.env === 'development' || eventData.env === 'staging') {
    console.log('Tracking event', eventData)
  }
  // Track event through Google Tag Manager
  window.dataLayer.push(eventData)
}
