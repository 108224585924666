up.compiler('[quiz-slide-resolve]', (elem) => {
  up.on(elem, 'click', () => {
    const slide = document.querySelector('.quiz-slide')
    const answers = slide.querySelectorAll('.quiz-slide-answer')

    answers.forEach((answer) => {
      if (up.element.booleanAttr(answer, 'solution')) {
        answer.classList.add('-correct')
      } else {
        answer.classList.add('-incorrect')
      }
    })

    let correctSolutions = 0
    let incorrectSolutions = 0

    const correctAnswers = slide.querySelectorAll('.quiz-slide-answer.-correct')

    answers.forEach((answer) => {
      if (answer.classList.contains('-correct') && answer.querySelector('input').checked) {
        correctSolutions = correctSolutions + 1
      } else if (answer.classList.contains('-incorrect') && answer.querySelector('input').checked) {
        incorrectSolutions = incorrectSolutions + 1
      }
    })

    slide.querySelector('.quiz-slide--solution').innerHTML = `${correctSolutions} von ${correctAnswers.length} richtig - ${incorrectSolutions} falsch`
  })
})
