export default class Timeout {
  constructor(callback, microseconds = 0) {
    this.callback = callback
    this.timerId = null
    this.startedAt = null
    this.initialMicroseconds = microseconds
    this.microsecondsLeft = microseconds
    this.paused = false
    this.resume()
  }

  get percent() {
    const percent = Math.round(100 * this.elapsed / this.initialMicroseconds)
    return Math.max(0, Math.min(100, percent))
  }

  get percentRemaining() {
    return 100 - this.percent
  }

  get elapsed() {
    return this.initialMicroseconds - this.remaining
  }

  get remaining() {
    return Math.max(0, this.microsecondsLeft - this._getMicrosecondsSinceLastStarted())
  }

  pause() {
    this.clear()
    this.microsecondsLeft -= this._getMicrosecondsSinceLastStarted()
    this.paused = true
  }

  resume() {
    this.clear()
    this.startedAt = new Date()
    this.timerId = window.setTimeout(this.callback, this.microsecondsLeft)
    this.paused = false
  }

  clear() {
    window.clearTimeout(this.timerId)
  }

  _getMicrosecondsSinceLastStarted() {
    return this.paused ? 0 : new Date() - this.startedAt
  }
}
