up.compiler('.layout', (element) => {

  let debounceTimeout
  const debounceDelay = 100

  function update() {
    if (up.layer.current === up.layer.root) {
      element.style.setProperty('--scrollbar-width', `${window.innerWidth - document.body.clientWidth}px`)
    }
  }

  function debouncedUpdate() {
    clearTimeout(debounceTimeout)
    debounceTimeout = setTimeout(update, debounceDelay)
  }

  const resizeObserver = new ResizeObserver(debouncedUpdate)
  resizeObserver.observe(element)

  update()

  return function onDestroy() {
    resizeObserver.disconnect()
  }

})
