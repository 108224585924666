up.compiler('.filter-target-groups', (element, { targets }) => {
  const select = element.querySelector('select')

  if (select) {
    select.addEventListener('change', () => {
      requestFromSelection()
    })
  } else {
    const radioButtons = element.querySelectorAll('input[type="radio"]')
    radioButtons.forEach(radio => {
      radio.addEventListener('change', () => {
        requestFromSelection()
        radioButtons.forEach(radio => radio.parentElement.classList.remove('selected'))
        radio.parentElement.classList.add('selected')
      })
    })
    initialSetup(radioButtons)
  }

  function requestFromSelection() {
    const fields = element.querySelectorAll('select, input')
    const params = up.Params.fromFields(fields)
    const strippedUrl = up.Params.stripURL(location.href)
    up.visit(strippedUrl, { params, target: targets, history: true })
  }

  function initialSetup(radioButtons) {
    const url = new URL(location.href)
    const searchParams = new URLSearchParams(url.search)
    const targetGroupParams = searchParams.get('target_group')
    radioButtons.forEach(radio => {
      if ((!targetGroupParams && radio.value === '') || (targetGroupParams === radio.value)) {
        radio.checked = true
        radio.parentElement.classList.toggle('selected')
      }
    })
  }

})
