import { gameState } from '../util/game_state'

up.compiler('[next-slide-button]', (element) => {
  function setButtonState() {
    element.classList.toggle('btn-visually-disabled', gameState.gameRunning)
  }

  element.addEventListener('click', (event) => {
    if (element.classList.contains('btn-visually-disabled')) {
      event.preventDefault()

      if (confirm('Möchtest du das Spiel abgeben?')) {
        gameState.submit.trigger()
      }
    }
  })

  setButtonState()
  gameState.changeRunning.register(setButtonState)

  return function destroy() {
    gameState.changeRunning.unregister(setButtonState)
  }
})
