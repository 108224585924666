import { Sortable } from 'sortablejs'

up.compiler('.drop-zone', (element) => {
  const draggableContainerElements = document.querySelectorAll('.draggable-container')
  draggableContainerElements.forEach((draggableContainerElement) => {
    // eslint-disable-next-line no-new
    new Sortable(draggableContainerElement, {
      group: {
        name: 'shared',
        pull: 'clone',
        put: false,
      },
      draggable: '.draggable-item',
      animation: 150,
      sort: false,
      onClone: (event) => { up.hello(event.clone) },
    })
  })

  const courseZoneDropArea = element.closest('.course-form--drop-area')
  // eslint-disable-next-line no-new
  new Sortable(courseZoneDropArea, {
    group: 'shared',
    animation: 150,
    onSort: () => { up.emit(element, 'list:updated') },
  })
})
