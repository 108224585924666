import consentManager from '../modules/consent_manager'
import environment from '../../shared/util/environment'

const embedLogForTests = {}

up.compiler('[script-with-consent]', (element, data) => {

  const consentTypes = data.consentTypes
  const enabled = data.enabled

  const script = data.script
  const src = data.src

  const scriptElement = document.createElement('script')
  if (script) scriptElement.innerHTML = data.script
  if (src) scriptElement.src = data.src

  const tests = environment.isTest
  if (tests) window.scriptWithConsentLog = embedLogForTests

  function embed() {
    if (enabled) {
      document.body.appendChild(scriptElement)
    } else {
      const scriptHtml = scriptElement.outerHTML
      console.log(`Would now embed ${consentTypes} script:\n${scriptHtml}`)

      if (tests) {
        if (!embedLogForTests[consentTypes]) embedLogForTests[consentTypes] = []
        embedLogForTests[consentTypes].push(scriptHtml)
      }
    }
  }

  consentManager.requireConsents(consentTypes).then(embed)
})
