import Sortable from '../util/sortable'

export default class SortableWithSingleDropTargets {
  constructor(sortableContainer, dropAreas, draggableSelector) {
    const groupName = `sortable-group-${Math.random()}`

    function onMove({ to }) {
      // allow dragging only into empty drop areas, or back into the container
      return to === sortableContainer || !to.hasChildNodes()
    }

    function onEnd({ item }) {
      up.emit(item, 'text:resize')

      // apply and remove a class to allow animation
      item.classList.add('sortable-dropped')
      window.setTimeout(() => {
        item.classList.remove('sortable-dropped')
      }, 300)
    }

    this.sortables = [sortableContainer, ...dropAreas].map((element) => {
      return new Sortable(element, {
        group: groupName,
        draggable: draggableSelector,
        animation: 150,
        onMove,
        onEnd,
      })
    })
  }

  destroy() {
    this.sortables.forEach(sortable => sortable.destroy())
    this.sortables.length = 0
  }
}
