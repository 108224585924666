class Application {

  constructor() {
    this.configurations = {}
  }

  configure(configKey, data) {
    const config = new Configuration(data)
    this.configurations[configKey] = config
    return config
  }

  getConfiguration(configKey) {
    return this.configurations[configKey]
  }

}

class Configuration {

  constructor(data) {
    for (const [key, value] of Object.entries(data)) {
      Object.defineProperty(this, key, {
        get: () => value,
        configurable: true, // to allow Jasmine's spyOnProperty
      })
    }
  }

}

export default new Application()
