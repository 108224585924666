import { Sortable, OnSpill, Swap } from 'sortablejs/modular/sortable.core.esm.js'

Sortable.mount(OnSpill)
Sortable.mount(new Swap())

let dragging = false

export default class SortableWithScrollPatch extends Sortable {
  // SortableJS comes with its own autoscrolling, but that is jerky on Android and
  // entirely broken on iOS because iOS Safari does not emit drag events.
  // We fix that by scrolling ourselves. It's not smooth, but at least consistent and reliable.
  constructor(element, options) {
    super(element, {
      scroll: false,
      onChoose,
      onUnchoose,
      ...options,
    })
  }
}

function onChoose() {
  dragging = true
}

function onUnchoose({ item }) {
  dragging = false
  item.scrollingElement = null
}

function onTouchMove({ touches }) {
  // For iOS Safari
  if (dragging) {
    onDrag(touches[0])
  }
}

function onDrag({ target, clientX, clientY }) {
  if (clientX === 0 && clientY === 0) return // when dragging stops, and event with 0/0 is emitted on e.g. Desktop Chrome

  // determine scrolling container only once per drag interaction for performance; it will be reset in `onUnchoose`.
  target.scrollingElement ??= getScrollingElement(target)

  const { scrollingElement } = target
  const { clientHeight } = scrollingElement
  const padding = Math.min(150, clientHeight / 4)

  const { offsetTop, scrollTop } = scrollingElement
  const scrollBottom = scrollTop + clientHeight
  const layerY = (clientY - offsetTop + scrollTop) // like pageY, but works for scrolling containers (and in iOS Safari)

  if (layerY - padding < scrollTop) {
    setScrollTop(scrollingElement, layerY - padding)
  } else if (layerY + padding > scrollBottom) {
    setScrollTop(scrollingElement, layerY + padding - clientHeight)
  }
}

function getScrollingElement(element) {
  if (!element || element === document.body) {
    // do this explicitly for <body> because its scrollHeight is larger than its clientHeight
    // even when the <html> element is the document's scrollingElement.
    return document.scrollingElement
  } else if (element.scrollHeight > element.clientHeight) {
    return element
  } else {
    return getScrollingElement(element.parentNode)
  }
}

function setScrollTop(scrollingElement, top) {
  top = Math.round(Math.max(0, top))

  // Avoid smooth-scrolling, because we might run again while the browser is still trying to smooth-scroll,
  // leading to weird scroll speeds which are inconsistent across different browsers/OSes.
  // The low FPS rate is barely noticeable to the average user, but the consistent speed will be.
  scrollingElement.style.scrollBehavior = 'auto'
  scrollingElement.scrollTo({ top })
  scrollingElement.style.scrollBehavior = null
}

function throttledCallback(callback) {
  let throttled = false

  return function(...args) {
    if (!throttled) {
      throttled = true
      window.setTimeout(() => { throttled = false }, 30)

      callback.apply(this, args)
    }
  }
}

document.documentElement.addEventListener('touchmove', throttledCallback(onTouchMove), { passive: true })
document.documentElement.addEventListener('drag', throttledCallback(onDrag), { passive: true })
