import NuggetComparator from './nugget_comparator'

export default class NuggetFinder {
  constructor(nuggets) {
    this.nuggets = nuggets
  }

  findByCopy(nuggetCopy) {
    const comparator = new NuggetComparator(nuggetCopy)
    return up.util.find(this.nuggets, (nugget) => {
      return comparator.matches(nugget)
    })
  }
}
