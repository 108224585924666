import 'unpoly/unpoly.js'
import 'unpoly/unpoly.css'
import 'unpoly/unpoly-bootstrap5.css'

import './frontend/config/unpoly.js'
import './frontend/config/bootstrap.sass'

import './frontend/theme.sass'

import './shared/components/**/*.js'
import './shared/components/**/*.sass'

import './frontend/components/**/*.js'
import './frontend/components/**/*.sass'

import 'tom-select/dist/js/tom-select.base'
