up.compiler('.filter-content-types', (element, { targets }) => {
  const select = element.querySelector('select')
  const tomSelectInput = element.querySelector('.ts-control')
  setInputText(select.tomSelect.items.length)

  select.addEventListener('change', () => {
    setInputText(select.tomSelect.items.length)
    const fields = element.querySelectorAll('select')
    const newParams = up.Params.fromFields(fields)
    const oldParams = up.Params.fromURL(location.href)
    oldParams.delete('main_content_type[]')
    newParams.addAll(oldParams)
    const strippedUrl = up.Params.stripURL(location.href)
    up.visit(strippedUrl, { params: newParams, target: targets, history: true })
  })

  function setInputText(itemsCount) {
    tomSelectInput.querySelectorAll('.item[data-ts-item]').forEach(item => item.setAttribute('hidden', true))
    const placeholder = tomSelectInput.querySelector('span.item')
    let text = `(${itemsCount})`
    if (itemsCount === 0) {
      text = 'alle'
    }
    if (placeholder) {
      placeholder.innerText = `Inhaltstypen: ${text}`
    } else {
      up.element.affix(tomSelectInput, 'span.item', { text: `Inhaltstypen: ${text}` })
    }
  }
})
