up.compiler('[track-page-view]', (element, {
  identity,
  resetIdentity,

  status,
  title,
  path,
  referrer,

  course,
  courseUnit,
  coursePage,
}) => {
  const { _paq } = window

  if (resetIdentity) {
    _paq.push(['resetUserId'])
  }
  _paq.push(['setUserId', identity])
  _paq.push(['setDocumentTitle', title])
  _paq.push(['setCustomUrl', path])
  if (referrer) {
    _paq.push(['setReferrerUrl', referrer])
  }

  setCustomDimension(1, status)
  setCustomDimension(2, course)
  setCustomDimension(3, courseUnit)
  setCustomDimension(4, coursePage)

  _paq.push(['trackPageView'])
})

function setCustomDimension(id, value) {
  if (value !== undefined) {
    window._paq.push(['setCustomDimension', id, value])
  }
}
