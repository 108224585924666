up.compiler('[navbar-toggler]', (element) => {
  const open = element.querySelector('.toggler--open')
  const close = element.querySelector('.toggler--close')

  element.addEventListener('click', () => {
    open.toggleAttribute('hidden')
    close.toggleAttribute('hidden')
  })

  close.addEventListener('click', () => {
    if (up.layer.count > 1) {
      up.layer.front.dismiss()
    }
  })
})
