up.compiler('.toggle-filter-button', (element, { target }) => {
  const targetElement = document.querySelector(target)

  element.addEventListener('click', function() {
    if (element.textContent.includes('Weitere Filter einblenden')) {
      targetElement.classList.add('slide-down')
      targetElement.classList.remove('slide-up')
      element.textContent = 'Filter ausblenden'
    } else {
      targetElement.style.setProperty('--height', `${targetElement.offsetHeight}px`)
      targetElement.classList.add('slide-up')
      targetElement.classList.remove('slide-down')
      element.textContent = 'Weitere Filter einblenden'
    }
    element.blur()
  })
})
