import consentManager from '../modules/consent_manager'
import { trackEvent } from '../util/analytics'

up.compiler('[cookie-consent-dialog]', (element) => {
  const form = element.querySelector('form')
  const checkboxes = form.querySelectorAll('input[type="checkbox"]')
  const acceptAllButton = element.querySelector('[cookie-consent-dialog--accept-all]')
  const acceptSelectionButton = element.querySelector('[cookie-consent-dialog--accept-selection]')

  function init() {
    trackEvent('cookieConsentDialog.visible')

    const consentedTypes = consentManager.consentedTypes
    const consentedCheckboxes = up.util.filter(checkboxes, checkbox => consentedTypes.includes(checkbox.value))
    setChecked(consentedCheckboxes)

    acceptSelectionButton.addEventListener('click', acceptSelection)
    acceptAllButton.addEventListener('click', acceptAll)
  }

  function acceptSelection() {
    trackEvent('cookieConsentDialog.acceptSelection')
    saveAndClose()
  }

  function acceptAll() {
    trackEvent('cookieConsentDialog.acceptAll')
    setChecked(checkboxes)
    window.setTimeout(saveAndClose, 200)
  }

  function setChecked(checkboxes) {
    checkboxes.forEach((checkbox) => { checkbox.checked = true })
  }

  function saveAndClose() {
    const consentedTypes = up.util.filter(checkboxes, 'checked').map(checkbox => { return checkbox.value })
    setConsentedTypes(consentedTypes)
    up.layer.dismiss()
  }

  function setConsentedTypes(consentedTypes) {
    const previouslyConsentedTypes = consentManager.consentedTypes

    trackEvent('cookieConsentDialog.setConsentedTypes', {
      consentedTypes,
      previouslyConsentedTypes,
    })

    consentManager.consentedTypes = consentedTypes

    if (previouslyConsentedTypes.some(type => !consentedTypes.includes(type))) {
      // User revoked consent for a previously given type. We need to reload the application.
      window.location.reload()
    }
  }

  init()
})
