up.compiler('.search', (element) => {

  const input = element.querySelector('input[type="text"]')

  element.addEventListener('submit', onSubmit)

  function onSubmit(event) {
    const value = getValue()

    if (!value) {
      event.preventDefault()
      input.focus()
    }
  }

  function getValue() {
    return input.value.trim()
  }

})
