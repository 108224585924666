import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'

function revealSource(target) {
  const imageUrl = target.getAttribute('lazy-src')

  if (imageUrl) {
    target.src = imageUrl
    target.classList.add('lazyloaded')
  }
}

function revealBackground(target) {
  const backgroundImageUrl = target.getAttribute('lazy-bg')
  if (backgroundImageUrl) {
    target.style.backgroundImage = `url("${backgroundImageUrl}")`
    target.classList.add('lazy-bg-loaded')
  }
}

document.addEventListener('lazybeforeunveil', (event) => {
  const target = event.target
  revealSource(target)
  revealBackground(target)
})
