export default class NuggetComparator {
  constructor(nugget) {
    this.nugget = nugget
  }

  matches(otherNugget) {
    return this.textMatches(otherNugget) && this.imageMatches(otherNugget)
  }

  textMatches(otherNugget) {
    return this.nugget.textContent === otherNugget.textContent
  }

  imageMatches(otherNugget) {
    return this.nugget.querySelector('img')?.src === otherNugget.querySelector('img')?.src
  }
}
