up.compiler('[copy-to-clipboard]', (element) => {
  up.on(element, 'click', () => {
    const copyText = element.getAttribute('copy-to-clipboard')
    navigator.clipboard.writeText(copyText).then(() => {
      element.classList.add('-success')
      setTimeout(function() {
        element.classList.remove('-success')
      }, 500)
    })
  })
})
