import { trackEvent } from '../util/analytics'

up.compiler('.assessment-slide', (element, { courseUnitId }) => {

  up.on(element, 'click', 'button[type="submit"]', (event, button) => {
    event.preventDefault()
    trackEvent('courseUnitAssessment', { courseUnitId, value: button.value })
    element.classList.add('-submitted')
  })
})
