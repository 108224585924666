import environment from '../util/environment'

up.link.config.followSelectors.push('a[href]')
up.link.config.instantSelectors.push('a[href]')
up.link.config.noInstantSelectors.push('.btn:not(.btn-link)', '.draggable-item a')

up.fragment.config.runScripts = false

up.feedback.config.currentClasses.push('active')

up.form.config.submitSelectors.push('form')

up.history.config.updateMetaTags = true

up.motion.config.enabled = !environment.isTest
