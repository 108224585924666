up.compiler('.standalone-preview', (elem) => {
  const navigateToLeft = elem.querySelector('.standalone-preview--previous-slide a')
  const navigateToRight = elem.querySelector('.standalone-preview--next-slide button')

  return up.on('keydown', (evt) => {
    if (evt.key === 'ArrowLeft' && navigateToLeft) {
      navigateToLeft.click()
    } else if (evt.key === 'ArrowRight' && navigateToRight) {
      navigateToRight.click()
    }
  })
})
