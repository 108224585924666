up.compiler('.collapsible', (element) => {

  const ATTRIBUTE_NAME = 'data-collapsed'

  function getCollapsed() {
    return !!element.getAttribute(ATTRIBUTE_NAME)
  }

  function setCollapsed(makeCollapsed) {
    if (makeCollapsed) {
      element.setAttribute(ATTRIBUTE_NAME, true)
    } else {
      element.removeAttribute(ATTRIBUTE_NAME)
    }
  }

  function toggle() {
    setCollapsed(!getCollapsed())
  }

  const destructor = up.on(element, 'click', '.collapsible--toggle', toggle)

  return destructor

})
