import CallbackRegistry from './callback_registry'

class GameState {
  constructor() {
    this.gamesRunning = 0
    this.submit = new CallbackRegistry()
    this.changeRunning = new CallbackRegistry()
  }

  startGame() {
    this.gamesRunning += 1
    this.changeRunning.trigger()
  }

  stopGame() {
    this.gamesRunning -= 1
    this.changeRunning.trigger()
  }

  get gameRunning() {
    return this.gamesRunning > 0
  }
}

export const gameState = new GameState()
