export default new class Environment {

  get toString() {
    return document.body.getAttribute('data-environment')
  }

  get isTest() {
    return this.toString === 'test'
  }

  get isDevelopment() {
    return this.toString === 'development'
  }

}()
