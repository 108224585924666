up.compiler('.filter-project-components', (element, { targets }) => {
  const checkBoxes = element.querySelectorAll('input[type="checkbox"]')
  checkBoxes.forEach(checkbox => {
    checkbox.addEventListener('change', () => {
      requestFromSelection()
      checkbox.parentElement.classList.toggle('selected')
    })
  })
  initialSetup()

  function requestFromSelection() {
    const fields = element.querySelectorAll('input')
    const newParams = up.Params.fromFields(fields)
    const oldParams = up.Params.fromURL(location.href)
    oldParams.delete('project_component[]')
    newParams.addAll(oldParams)
    const strippedUrl = up.Params.stripURL(location.href)
    up.visit(strippedUrl, { params: newParams, target: targets, history: true })
  }

  function initialSetup() {
    const url = new URL(location.href)
    const searchParams = new URLSearchParams(url.search)
    const projectComponentSearchParams = searchParams.getAll('project_component[]')
    checkBoxes.forEach(checkbox => {
      if (projectComponentSearchParams?.includes(checkbox.value)) {
        checkbox.checked = true
        checkbox.parentElement.classList.toggle('selected')
      }
    })
  }

})
