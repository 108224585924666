export default class CallbackRegistry {
  constructor() {
    this.callbacks = []
  }

  trigger() {
    this.callbacks.forEach((callback) => { callback() })
  }

  register(callback) {
    this.callbacks.push(callback)
  }

  unregister(callback) {
    const index = this.callbacks.indexOf(callback)
    this.callbacks.splice(index, 1)
  }
}
