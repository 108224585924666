export default class VideoPlayButton {

  constructor(video, button) {
    this.video = video
    this.button = button
  }

  connect() {
    this.button.addEventListener('click', this.playVideo.bind(this))
    this.video.addEventListener('play', this.toggleButton.bind(this))
    this.video.addEventListener('pause', this.toggleButton.bind(this))
  }

  disconnect() {
    this.button.removeEventListener('click', this.playVideo.bind(this))
    this.video.removeEventListener('play', this.toggleButton.bind(this))
    this.video.removeEventListener('pause', this.toggleButton.bind(this))
  }

  async playVideo() {
    if (typeof jasmine === 'undefined') {
      // somehow waiting for the promise breaks stubs from jasmine specs, so we don't reveal in Jasmine specs
      await up.reveal(this.video, { padding: 30 })
    }
    this.video.play()
  }

  toggleButton() {
    this.button.toggleAttribute('disabled', !this.video.paused)
  }

}
