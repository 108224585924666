up.compiler('.course-form--drop-area', (element) => {

  const courseUnitIdsField = element.querySelector('input#course_course_unit_ids')

  if (!courseUnitIdsField) {
    throw new Error('Missing field for course unit IDs')
  }

  function onUpdate() {
    const courseUnits = element.querySelectorAll('.course-unit')
    const courseUnitIds = [...courseUnits]
      .map(node => node.dataset.elementId)
      .filter(Number)

    courseUnitIdsField.value = courseUnitIds.join(',')
    element.classList.toggle('-empty', courseUnits.length < 1)
  }
  onUpdate()

  up.on(element, 'click', '[remove-course-unit]', ({ target }) => {
    target.closest('.course-unit').remove()
    onUpdate()
  })

  up.on(element, 'list:updated', onUpdate)

})
