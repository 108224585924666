up.compiler('[confirm-account-deletion]', (element) => {

  const message = 'Zum dauerhaften Löschen Ihres Accounts, tippen Sie bitte das Wort LÖSCHEN ein.'
  const requiredInput = 'LÖSCHEN'

  function onClick(event) {
    const input = prompt(message)
    const sanitizedInput = (input || '').trim().toUpperCase()
    const expectedInput = requiredInput.trim().toUpperCase()

    if (input === null) {
      alert('Sie haben abgebrochen. Ihr Account wurde nicht gelöscht.')
      event.preventDefault()
    } else if (sanitizedInput !== expectedInput) {
      alert(`Ihre Eingabe war „${sanitizedInput}“ statt „${expectedInput}“. Ihr Account wurde nicht gelöscht.`)
      event.preventDefault()
    } else {
      // submit form to delete account
    }
  }

  element.addEventListener('click', onClick)

})
