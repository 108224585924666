import MultipleChoice from '../util/multiple_choice'
import { gameState } from '../util/game_state'

up.compiler('[multiple-choice]', (element) => {
  const multipleChoice = new MultipleChoice(element)
  const button = element.querySelector('[multiple-choice--submit-button]')

  let submitted = false

  up.on(button, 'click', onClickSubmit)
  gameState.submit.register(onClickSubmit)

  function onClickSubmit() {
    submit()
    multipleChoice.checkAnswers()
  }

  function submit() {
    button.remove()
    gameState.stopGame()
    submitted = true
  }

  return function destroy() {
    gameState.submit.unregister(onClickSubmit)
    if (!submitted) {
      gameState.stopGame()
    }
  }
})
