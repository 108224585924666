import cookieConsentSettings from '../modules/cookie_consent_settings'

up.macro('[disable-cookie-consent-dialog]', () => {

  function init() {
    cookieConsentSettings.enabled = false
  }

  function onDestroy() {
    // When transitioning to a page that also disables the cookie consent, we don't want to re-enable.
    if (up.fragment.get('[disable-cookie-consent-dialog]')) return

    cookieConsentSettings.enabled = true
  }

  init()
  return onDestroy
})
