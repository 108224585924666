import TomSelect from 'tom-select/dist/js/tom-select.base'
import changeListener from 'tom-select/dist/js/plugins/change_listener'
import checkboxOptions from 'tom-select/dist/js/plugins/checkbox_options'
import { createPopper } from '@popperjs/core/lib/popper-lite'
import flip from '@popperjs/core/lib/modifiers/flip'
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow'

TomSelect.define('change_listener', changeListener)
TomSelect.define('checkbox_options', checkboxOptions)

up.compiler('select', (element, customizations) => {

  if (element.closest('[no-edit]')) {
    return
  }

  const defaultOptions = {
    create: false,

    searchField: ['text', 'searchTags'],
    controlInput: null,
    hidePlaceholder: false,
    allowEmptyOption: true,
    render: {
      create: (data, escape) => { return `<div class="create><strong>${escape(data.input)}</strong></div>` },
      no_results: () => { return '<div class="no-results">Keine Übereinstimmungen gefunden</div>' },
    },
    plugins: [
      'change_listener',
      element.closest('.filter-content-types') ? 'checkbox_options' : '',
    ],
    onInitialize() {
      this.dropdown.style.margin = '0' // needed to remove popper.js warnings due to margin in the browser console
      this.popper = createPopper(this.control, this.dropdown, {
        modifiers: [flip, preventOverflow],
      })
    },
    dropdownParent: element.closest('up-modal-viewport') || null,
  }

  const options = Object.assign({}, defaultOptions, customizations)
  const tomSelect = new TomSelect(element, options)

  tomSelect.positionDropdown = function() {
    this.popper.update()
    this.dropdown.style.width = `${this.control.getBoundingClientRect().width}px`
  }

  tomSelect.on('destroy', function() {
    this.popper.destroy()
  })

  element.tomSelect = tomSelect

  // Clean up when the element gets removed from the DOM.
  return () => {
    tomSelect.destroy()
  }

})
