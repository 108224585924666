up.compiler('[active-on-clicked]', function(element) {
  up.on(element, 'click', (evt) => {
    // in feature specs: spec is too fast and still shows Unpoly's .up-active class
    const classNames = element.getAttribute('class').replaceAll(' up-active', '').replaceAll(' ', '.')
    document.querySelectorAll(`.${classNames}`).forEach((sibling) => {
      sibling.classList.remove('-active')
    })
    element.classList.add('-active')
  })
})
