export default class MultipleChoice {
  constructor(element) {
    this.element = element
    this.answersSetInResults = false
  }

  checkAnswers() {
    const choices = this.element.querySelector('[multiple-choice--choices]')
    const results = this.element.querySelector('[multiple-choice--results]')

    const inputs = choices.querySelectorAll('input')

    let allCorrect = true

    for (const input of inputs) {
      const checked = input.checked
      const inputIndex = input.dataset.index
      const expectChecked = input.dataset.correct === 'true'

      results.querySelector(`[multiple-choice--answer-${inputIndex}="${checked}"]`).removeAttribute('hidden')

      if (checked !== expectChecked) {
        allCorrect = false
      }

      input.disabled = true
    }

    if (allCorrect) {
      this.setNotification('success', this.getRandomSuccessMessage())
    } else {
      this.setNotification('failure', this.getRandomFailureMessage())
    }

    this.answersSetInResults = true
    this.showSolution()
  }

  setNotification(state, message) {
    const notification = this.element.querySelector('[multiple-choice--notification]')
    notification.innerText = message
    notification.className = notification.className.replace(/(^|\s)-\w+/g, '') // remove all modifiers
    notification.classList.add(`-${state}`)
  }

  getRandomSuccessMessage() {
    switch (Math.floor(Math.random() * 3)) {
      case 0:
        return 'Prima – alles richtig! Unten finden Sie dazu eine Erklärung.'
      case 1:
        return 'Klasse, das stimmt! Eine Erklärung finden Sie unten.'
      default:
        return 'Gut gemacht! Unten finden Sie eine Erklärung zu der Frage.'
    }
  }

  getRandomFailureMessage() {
    switch (Math.floor(Math.random() * 3)) {
      case 0:
        return 'Schade! Das ist leider nicht richtig. Sehen Sie sich die Erklärung genau an.'
      case 1:
        return 'Leider nein! Das ist nicht richtig. Lesen Sie sich die Erklärung gut durch.'
      default:
        return 'Das stimmt so nicht! Sehen Sie sich die Erklärung unten gut an.'
    }
  }

  showSolution() {
    if (!this.answersSetInResults) {
      this.element.querySelector('[multiple-choice--results]').classList.add('-no-answers')
    }

    this.element.querySelector('[multiple-choice--choices]')?.setAttribute('hidden', true)

    this
      .element
      .querySelectorAll('[multiple-choice--revealable]')
      .forEach((hiddenElement) => {
        hiddenElement.removeAttribute('hidden')
      })
  }

  skipToSolution() {
    this.showSolution()
  }
}
