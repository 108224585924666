class CookieConsentSettings {
  constructor() {
    this._enabled = true
  }

  get enabled() {
    return this._enabled
  }

  set enabled(enabled) {
    const wasEnabled = this._enabled
    this._enabled = enabled

    if (enabled !== wasEnabled) {
      up.emit('app:cookieConsentDialog:enabledChanged')
    }
  }
}

export default new CookieConsentSettings()
