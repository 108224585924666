// we allowlist some javascript: links and eval them ourselves, otherwise CSP will block execution

up.compiler('a[href^="javascript:"]', (element) => {
  element.addEventListener('click', (event) => {
    event.preventDefault()
    const javascriptCode = element.href.split(':')[1].trim()
    switch (javascriptCode) {
      case 'gaOptout()': {
        // Method is provided through Google Tag Manager
        window.gaOptout()
        break
      }
    }
  })
})
