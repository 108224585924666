import MultipleChoice from '../util/multiple_choice'
import { gameState } from '../util/game_state'
import VideoPlayButton from '../util/video_play_button'

up.compiler('[video-multiple-choice]', (element) => {
  const multipleChoice = new MultipleChoice(element)
  const questionVideo = element.querySelector('[multiple-choice--question-video]')
  const solutionVideo = element.querySelector('[multiple-choice--solution-video]')
  const choices = element.querySelector('[multiple-choice--choices]')
  const startButton = element.querySelector('[multiple-choice--start-button]')
  const submitButton = element.querySelector('[multiple-choice--submit-button]')
  const replayButton = element.querySelector('[multiple-choice--replay-button]')

  let submitted = false

  up.on(questionVideo, 'ended', showChoicesAndAdjustControls)
  up.on(submitButton, 'click', onClickSubmit)
  up.on(replayButton, 'click', replayVideo)
  gameState.submit.register(onClickSubmit)

  const videoPlayButton = new VideoPlayButton(questionVideo, startButton)
  videoPlayButton.connect()

  function showChoicesAndAdjustControls() {
    choices.removeAttribute('hidden')
    replayButton.removeAttribute('hidden')
    startButton.setAttribute('hidden', true)
    questionVideo.controls = false

    submitButton.removeAttribute('hidden')
  }

  async function onClickSubmit() {
    submit()
    multipleChoice.checkAnswers()
    showAndPlaySolutionVideo()
  }

  function submit() {
    submitButton.remove()
    gameState.stopGame()
    submitted = true
  }

  function showAndPlaySolutionVideo() {
    questionVideo.setAttribute('hidden', true)
    replayButton.setAttribute('hidden', true)
    solutionVideo.removeAttribute('hidden')

    solutionVideo.play().catch(() => {
      // User probably did not interact with the page yet. Browsers do not allow video playback in that case,
      // and there is nothing we can do except silencing the error.
    })
  }

  function replayVideo() {
    replayButton.setAttribute('hidden', true)
    questionVideo.controls = true
    questionVideo.play()
  }

  return function destroy() {
    gameState.submit.unregister(onClickSubmit)
    videoPlayButton.disconnect()
    if (!submitted) {
      gameState.stopGame()
    }
  }
})
