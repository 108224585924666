import consentManager from '../modules/consent_manager'
import cookieConsentSettings from '../modules/cookie_consent_settings'

up.compiler('[cookie-consent-dialog-opener]', (element, data) => {
  const selector = data.selector
  const html = data.html

  function maybeShowModal() {
    if (cookieConsentSettings.enabled && consentManager.consentedTypes.length < 1 && !up.layer.isOverlay()) {
      showModal()
    }
  }

  function showModal() {
    up.layer.open({ target: selector, fragment: html, mode: 'modal', dismissable: false, animation: false, size: 'medium' })
  }

  maybeShowModal()

  return [
    up.on('app:cookieConsentDialog:show', showModal),
    up.on('app:cookieConsentDialog:enabledChanged', maybeShowModal),
  ]
})
