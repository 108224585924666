import Cookies from 'js-cookie'
import Deferred from './deferred'
import { trackEvent } from '../util/analytics'

const COOKIE_NAME = 'consented_cookie_types'
const SEPARATOR = ','

export default new class ConsentManager {
  constructor() {
    this._deferreds = {}
    this._consentedTypes = this._readConsentedTypes()
    this._giveAllConsentedTypes()
  }

  get consentedTypes() {
    return this._consentedTypes
  }

  set consentedTypes(consentedTypes) {
    this._consentedTypes = consentedTypes
    this._writeConsentedTypes()
    this._giveAllConsentedTypes()
  }

  requireConsent(consentType) {
    return this._deferred(consentType).promise
  }

  requireConsents(consentTypes) {
    const promises = consentTypes.map(consentType => this.requireConsent(consentType))
    return Promise.all(promises)
  }

  _give(consentType) {
    trackEvent('cookieConsent.given', { type: consentType })
    this._deferred(consentType).resolve()
  }

  _giveAllConsentedTypes() {
    this.consentedTypes.forEach((consentType) => this._give(consentType))
  }

  _readConsentedTypes() {
    const serializedValue = Cookies.get(COOKIE_NAME)
    return serializedValue ? serializedValue.split(SEPARATOR) : []
  }

  _writeConsentedTypes() {
    const serializedValue = this.consentedTypes.join(SEPARATOR)
    Cookies.set(COOKIE_NAME, serializedValue, { expires: 365 })
  }

  _deferred(consentType) {
    if (!this._deferreds[consentType]) {
      this._deferreds[consentType] = new Deferred()
    }
    return this._deferreds[consentType]
  }
}()
