export default class ImageAssignment {
  constructor(sourceContainer, destinationContainer, destinationDropAreaSelector, draggableElementSelector, explanationContainer) {
    this.destinationContainer = destinationContainer
    this.destinationDropAreaSelector = destinationDropAreaSelector
    this.draggableElementSelector = draggableElementSelector
    this.explanationContainer = explanationContainer
    this.destinationContainerCopy = up.element.createFromHTML(destinationContainer.outerHTML)
    this.sourceContainerCopy = up.element.createFromHTML(sourceContainer.outerHTML)
    this.solution = null
  }

  get isCompleted() {
    let completed = true
    const destinationDropAreas = this.destinationContainer.querySelectorAll(this.destinationDropAreaSelector)
    for (const dropArea of destinationDropAreas) {
      const inputElement = dropArea.querySelector(this.draggableElementSelector)
      if (inputElement) {
        const match = inputElement.dataset.targetAreaReferences.includes(dropArea.dataset.reference)
        if (match) {
          this.markAsCorrect(dropArea)
        } else {
          completed = false
          this.markAsIncorrect(dropArea)
        }
      } else {
        completed = false
        this.markAsIncorrect(dropArea)
      }
    }
    return completed
  }

  markAsCorrect(dropArea) {
    dropArea.classList.remove('-incorrect')
    dropArea.classList.add('-correct')
  }

  markAsIncorrect(dropArea) {
    dropArea.classList.remove('-correct')
    dropArea.classList.add('-incorrect')
  }

  buildSolution(solutionContainer) {
    if (!this.solution) {
      const solutionDropAreas = this.destinationContainerCopy.querySelectorAll(this.destinationDropAreaSelector)
      for (const dropArea of solutionDropAreas) {
        const nugget = this.findNuggetByReference(this.sourceContainerCopy, dropArea.dataset.reference)
        dropArea.appendChild(nugget)
      }
      this.solution = this.destinationContainerCopy
    }
    solutionContainer.appendChild(this.solution)

    solutionContainer.appendChild(up.element.createFromHTML(this.explanationContainer.outerHTML))

    this.explanationContainer.removeAttribute('hidden')
  }

  findNuggetByReference(container, reference) {
    const selector = `${this.draggableElementSelector}[data-target-area-references*=${reference}]`
    return container.querySelector(selector)
  }
}
