up.compiler('[scale-to-fill-container]', (element) => {
  function isOverflown(parent) {
    const overflowWidth = element.clientWidth > parent.clientWidth
    const overflowHeight = element.clientHeight > parent.clientHeight
    return overflowHeight || overflowWidth
  }

  function resizeText() {
    const parent = element.parentElement
    const step = 1
    let i = 5

    while (i < 50) {
      setFontSize(i)
      if (isOverflown(parent)) {
        i -= step // revert to last state where no overflow happened
        break
      }

      i += step
    }

    setFontSize(i)
  }

  function setFontSize(fontSize) {
    element.style.fontSize = `${fontSize}px`
  }

  resizeText()

  element.addEventListener('text:resizeThis', resizeText)

  return up.on(window, 'resize', resizeText)
})

up.on('text:resize', ({ target }) => {
  const scalableElement = target.querySelector('[scale-to-fill-container]')
  if (scalableElement) {
    up.emit(scalableElement, 'text:resizeThis')
  }
})
